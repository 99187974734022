/**
 * Безопасно открывает URL в новой или текущей вкладке браузера
 * @param url - URL для открытия
 * @param newTab - открыть в новой вкладке (по умолчанию true)
 * @throws {Error} если URL невалидный или пустой
 */
export function openLink(url: string, newTab = true): void {
  // Проверка на валидность URL
  if (!url?.trim()) {
    throw new Error('Ссылка не может быть пустой')
  }

  try {
    // Проверяем и нормализуем URL
    const safeUrl = new URL(url).toString()

    // Определяем платформу и браузер
    const isIOS = navigator.platform.startsWith('iP') || navigator.platform.startsWith('Mac')
    const isChrome = /Chrome/.test(navigator.userAgent) && !/Edge/.test(navigator.userAgent)
    const isWindows = /Win/.test(navigator.platform)
    if (isChrome && isWindows && newTab) {
      try {
        const newWindow = window.open(safeUrl, '_blank')
        if (newWindow === null) {
          const link = document.createElement('a')
          link.href = safeUrl
          link.target = '_self'
          link.rel = 'noopener noreferrer nofollow'
          link.setAttribute('referrerpolicy', 'no-referrer')
          link.click()
          return
        }
        newWindow.opener = null
        return
      } catch {
        window.location.href = safeUrl
        return
      }
    }

    if (isIOS && newTab) {
      try {
        const newWindow = window.open()
        if (newWindow) {
          newWindow.opener = null
          newWindow.location.href = safeUrl
        } else {
          window.location.href = safeUrl
        }
      } catch {
        window.location.href = safeUrl
      }
      return
    }

    try {
      const link = document.createElement('a')
      link.href = safeUrl
      link.target = newTab ? '_blank' : '_self'
      link.rel = 'noopener noreferrer nofollow'

      if (newTab) {
        link.setAttribute('referrerpolicy', 'no-referrer')
      }

      link.style.display = 'none'
      document.body.appendChild(link)
      link.click()
      requestAnimationFrame(() => {
        document.body.removeChild(link)
      })
    } catch {
      window.location.href = safeUrl
    }
  } catch (error) {
    throw new Error('Ошибка открытия ссылки', { cause: error })
  }
}
